// src/App.js
import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase';
import './App.css';

function App() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('Signing you up...');

    try {
      await addDoc(collection(db, 'signups'), {
        email: email,
        timestamp: new Date()
      });
      setMessage(`You're all set! See you on the dance floor, ${email}!`);
      setEmail('');
    } catch (error) {
      console.error('Error adding document: ', error);
      setMessage('Oops! Something went wrong. Please try again.');
    }
  };

  return (
    <div className="App">
      <header>
        <h1>Connections</h1>
        <p className="tagline">Music, Dancing, Connections</p>
      </header>
      <main>
        <section className="about">
          <h2>About Connections</h2>
          <p>
            Connections is a networking dance party located in Buffalo, NY bringing you 
            good music, cocktails/mocktails, and connections.
          </p>
        </section>
        <section className="signup">
          <h2>Join the Party</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your email address"
              required
            />
            <button type="submit">Sign Up</button>
          </form>
          {message && <p className="message">{message}</p>}
        </section>
        <section className="venmo">
          <h2>Venmo</h2>
          <p>We are able to do this with the help of friends!</p>
          <a href="https://venmo.com/wayy-research">venmo.com/wayy-research</a>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 Wayy Research. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;