// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAzXPv9qB945-_-BepK9izc5XdsM0py5R4",
    authDomain: "connections-92618.firebaseapp.com",
    projectId: "connections-92618",
    storageBucket: "connections-92618.appspot.com",
    messagingSenderId: "1072239735836",
    appId: "1:1072239735836:web:3aa778ebab5967c9985bd9",
    measurementId: "G-W8N0Z7LLF7"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };